.productListContainer {
    /* border: 1px solid blue; */
    padding-top: 40px;
}

.productContainer {
    /* border: 1px solid red; */
    background-color: white;
    padding: 30px;
    margin-bottom: 30px;
}

.productContainer > img {
    width: 80%;
}

.productContainer > h2 {
    font-size: 1.2em;
}

.lilysButton {
    background-color: transparent;
    padding: 10px 30px;
}

.detailedProductContainer {
    padding: 40px;
    background-color: #fff;
}

.detailedProductImageContainer > img {
    width: 100%;
}

.formGroup {
    margin-bottom: 20px;
}