.logo {
    height: 150px;
    display: block;
    margin: 0 auto;
    max-width: 100%;
}

.header {
    padding-top: 20px;
    margin-bottom: 20px;
}