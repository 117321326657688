.page {
    min-height: 100vh;
    background-color: #F2E9E4;
    padding: 0px 10px;
}

.lilysButton {
    background-color: transparent;
    border: 2px solid #000;
    border-radius: 0px;
    color: #000!important;
}



.accordion-button, .accordionHeader {
    background-color: transparent!important;
}





.contactFormContainer {
    padding-top: 40px;
}

.contactFormLabel {
    text-align: left!important;
}

.contactFormInput {
    width: 400px;
    max-width: 100%;
}